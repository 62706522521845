import React, {useEffect, useState, useContext} from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";
import {BlogContext} from "../components/blogPage/context/blogContext";
import BlogResultsComponent from '../components/blogPage/blogResultsComponent'

const BlogResultes = (props) => {
    const { pageContext, data } = props
    const { edges } = data.allBlogData

    const pageSeo = {seo:{
                      MetaDesc: `Explore the latest blog posts and updates from ${pageContext.dealerName}. Stay informed by reading our latest articles, news, and tips!`,
                      MetaTitle: `Blog Posts | ${pageContext.dealerName}`,
                      tags: []}
                    }

    const blogManagement = pageContext.blogmanagement;
    const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
    const blogResultsBottomHTML = blogManagement.blogResultsBottomHTML;
    const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
    const { pageNumbers, currentPage, limit, skip } = pageContext
    const blogCountTotal = data.allBlogData.pageInfo.totalCount;
    const latestPost = props.data.allBlogData.edges[0].node.blogData;
    const latestPostTitle = JSON.parse(latestPost.pageTitle);
    const latestPostContent = JSON.parse(latestPost.content);
    const latestSlug = JSON.parse(latestPost.slug);

    // function to format publication date to display Month, Day, Year
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
          // Invalid date format, return input as is
          return inputDate;
      }

      const options = { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric' 
      };

      return date.toLocaleDateString(undefined, options);
    }

    // function to shorten blog title to specified number of characters and add elipsis at the end
    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }

    return (
      <Layout seo={pageSeo}>
        <div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />

        {/* Blog Results Banner Post */}
        {/* {currentPage === 1?
          <div className={`${view['blog-jumbotron']}`} style={{backgroundImage:`url(${latestPostContent.blogposts.thumbnailImage})`}}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', position:'absolute', background:'rgba(0,0,0,.4)', top:0, left:0, right:0, bottom:0}}>
              <div>
                <p style={{textAlign:'center', fontWeight:900, fontSize:25, padding:'10px', maxWidth:'450px', margin:'0 auto', color:'#fff' }}>{latestPostTitle}</p>
                <Link href={latestSlug} className={`${view['blog-jumbotron-button']}`}>Read More</Link>
              </div>
            </div>
          </div>
          :
          null
        } */}

        <div style={{ width: "95%", margin: '25px auto', maxWidth: 2000, padding: "0 15px", minHeight: "70vh"}}>
          <p style={{paddingLeft:10, color: "var(--primary-text)"}}>Showing {blogCountTotal} total blog results</p>
          <ul className={view["blog-entries__list"]}>

          {edges.map(data => {

            const id = data.node.blogData._id
            const pageTitle = JSON.parse(data.node.blogData.pageTitle);
            const slug = JSON.parse(data.node.blogData.slug);
            const content = JSON.parse(data.node.blogData.content);
            const images = content.blogposts.thumbnailImage;
            const datePublished = content.blogposts.publicationDate;
            let formattedDate = formatDate(datePublished);
            let truncatedTitle = truncateString(pageTitle, 100)

            return(

              <li className={view["blog-entry-card__container"]} key={id}>
                  <Link className={view["blog-entry-card"]} href={slug}>
                    <div className={view["blog-entries__image"]}>
                      <div style={{backgroundImage:`url(${images? images:null})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'center'}}></div>
                    </div>
                    <div className={view["blog-entry-card__flexible"]} style={{paddingTop:10}}>
                      {datePublished? <h3 className={view["blog-entry-date"]}>{formattedDate}</h3> : null }
                      {pageTitle? <p className={view["blog-entry-title"]}>{truncatedTitle}</p> : null }
                      <div className={view["blog-entry-read-more"]}>
                        <h3>READ MORE</h3>
                      </div>
                    </div>
                  </Link>
                </li>

            )
          })}
          </ul>  
          <BlogResultsComponent limit={limit} skip={skip} pageNumbers={pageNumbers} currentPage={currentPage} blogmanagement={blogManagement}/>
        </div>
        {/*BLOG RESULTS DATA */}
        <div dangerouslySetInnerHTML={{ __html: blogResultsBottomHTML }} />

        {/* ALL BLOG DATA */}
        <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
      </Layout>

    )
}

export const query = graphql`
  query newQuery($skip: Int!, $limit: Int!){
    allBlogData(skip: $skip, limit:$limit, sort: {order: DESC, fields: assigned_date}){
      edges {
        node {
          blogData {
            _id
            content
            pageTitle
            slug
          }
        }
      }
      pageInfo{
        itemCount
        totalCount
        pageCount
      }
    }
  }
`;



export default BlogResultes
